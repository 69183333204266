/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import form from '@/store/form'
import './assets/js/css/ImportCss'
import { Fancybox } from '@fancyapps/ui'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import Carte from '@/assets/js/Carte/Carte'
import MapIframe from '@/assets/js/Map/Iframe/MapIframe'
import HeaderFixed from '@/assets/js/Header/Fixed/HeaderFixed'
import HeaderMobile from '@/assets/js/Header/Mobile/HeaderMobile'
import Faq from '@/assets/js/Faq/Faq'
import ButtonTop from '@/assets/js/Button/Top/ButtonTop'
import Show from '@/assets/js/Show/Show'
import AppForm from '@/components/Form/AppForm.vue'
import AppPopupForm from '@/components/Form/Popup/AppPopupForm.vue'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-fancybox]', {
        mainClass: ''
    })

    Fancybox.bind('[data-fancybox-form]', {
        mainClass: 'fancybox---form'
    })

    new SwiperSlider()
    new Carte()
    new MapIframe()
    new HeaderFixed()
    new HeaderMobile()
    new Faq()
    new ButtonTop()
    new Show()

    if(document.getElementById('block--form') != null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form')
    }

    if(document.getElementById('block--form-popup') != null) {
        createApp({})
            .component('block-form-popup', AppPopupForm)
            .use(Maska)
            .use(form)
            .mount('#block--form-popup')
    }
})
