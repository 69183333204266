/*global $*/
/*eslint no-undef: "error"*/

import Swiper from 'swiper'
import {Autoplay, EffectCoverflow, Navigation, Pagination, Thumbs} from 'swiper/modules'
Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay, Thumbs])

export default class SwiperSlider {
    createService () {
        return new Swiper('#slider--service', {
            slidesPerView: 20,
            spaceBetween: 4,
            // loop: true,
            navigation: {
                nextEl: '.slider--service-next',
                prevEl: '.slider--service-prev',
            },
            pagination: {
                el: '.swiper-pagination--service',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                414: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 15
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                }
            }
        })
    }

    createNationality () {
        return new Swiper('#slider--nationality', {
            slidesPerView: 20,
            spaceBetween: 4,
            // loop: true,
            navigation: {
                nextEl: '.slider--nationality-next',
                prevEl: '.slider--nationality-prev',
            },
            pagination: {
                el: '.swiper-pagination--nationality',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                414: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 15
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                }
            }
        })
    }

    createHall () {
        return new Swiper('#slider--hall', {
            slidesPerView: 30,
            spaceBetween: 2,
            // loop: true,
            navigation: {
                nextEl: '.slider--hall-next',
                prevEl: '.slider--hall-prev',
            },
            pagination: {
                el: '.swiper-pagination--hall',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 20
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                },
                800: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                1240: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                1440: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                }
            }
        })
    }

    createGallery () {
        return new Swiper('#slider--gallery', {
            slidesPerView: 30,
            spaceBetween: 3,
            // loop: true,
            navigation: {
                nextEl: '.slider--gallery-next',
                prevEl: '.slider--gallery-prev',
            },
            pagination: {
                el: '.swiper-pagination--gallery',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 20
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                }
            }
        })
    }

    createBorder () {
        return new Swiper('.slider--border', {
            slidesPerView: 20,
            spaceBetween: 1,
            // loop: true,
            navigation: {
                nextEl: '.slider--border-next',
                prevEl: '.slider--border-prev',
            },
            pagination: {
                el: '.swiper-pagination--border',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 20
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                }
            }
        })
    }

    createProduct () {
        return new Swiper('#slider--product', {
            slidesPerView: 20,
            spaceBetween: 3,
            // loop: true,
            navigation: {
                nextEl: '.slider--product-next',
                prevEl: '.slider--product-prev',
            },
            pagination: {
                el: '.swiper-pagination--product',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 15
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 15
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                }
            }
        })
    }

    createCarte () {
        return new Swiper('#slider--carte', {
            slidesPerView: 20,
            spaceBetween: 1,
            // loop: true,
            navigation: {
                nextEl: '.slider--carte-next',
                prevEl: '.slider--carte-prev',
            },
            pagination: {
                el: '.swiper-pagination--carte',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 15
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 15
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                }
            }
        })
    }

    createPeople () {
        return new Swiper('#slider--people', {
            slidesPerView: 30,
            spaceBetween: 3,
            // loop: true,
            navigation: {
                nextEl: '.slider--people-next',
                prevEl: '.slider--people-prev',
            },
            pagination: {
                el: '.swiper-pagination--people',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                }
            }
        })
    }

    createReview () {
        return new Swiper('#slider--review', {
            slidesPerView: 20,
            spaceBetween: 2,
            // loop: true,
            navigation: {
                nextEl: '.slider--review-next',
                prevEl: '.slider--review-prev',
            },
            pagination: {
                el: '.swiper-pagination--review',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 20
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                }
            }
        })
    }

    createAbout () {
        return new Swiper('#slider--about', {
            slidesPerView: 20,
            spaceBetween: 4,
            // loop: true,
            navigation: {
                nextEl: '.slider--about-next',
                prevEl: '.slider--about-prev',
            },
            pagination: {
                el: '.swiper-pagination--about',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                360: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 4,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                },
                991: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                },
                1240: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                }
            }
        })
    }

    createBlock () {
        return new Swiper('#slider--block', {
            slidesPerView: 20,
            spaceBetween: 3,
            // loop: true,
            navigation: {
                nextEl: '.slider--block-next',
                prevEl: '.slider--block-prev',
            },
            pagination: {
                el: '.swiper-pagination--block',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                360: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                }
            }
        })
    }

    constructor () {
        if (parseInt($('#slider--service').length) > 0) {
            this.createService()
        }

        if (parseInt($('#slider--nationality').length) > 0) {
            this.createNationality()
        }

        if (parseInt($('#slider--hall').length) > 0) {
            this.createHall()
        }

        if (parseInt($('#slider--gallery').length) > 0) {
            this.createGallery()
        }

        if (parseInt($('.slider--border').length) > 0) {
            this.createBorder()
        }

        if (parseInt($('#slider--product').length) > 0) {
            this.createProduct()
        }

        if (parseInt($('#slider--carte').length) > 0) {
            this.createCarte()
        }

        if (parseInt($('#slider--people').length) > 0) {
            this.createPeople()
        }

        if (parseInt($('#slider--review').length) > 0) {
            this.createReview()
        }

        if (parseInt($('#slider--about').length) > 0) {
            this.createAbout()
        }

        if (parseInt($('#slider--block').length) > 0) {
            this.createBlock()
        }
    }
}
