<template lang="pug">
.form.form---popup(:class="{'form---send': sendForm}")
  .form__form
    .form__title(v-html="formTitle")

    .form__fields
      .form__field
        label.input-text
          input.input-text__input(
            type="text"
            v-model="fieldName"
            :placeholder="formFieldNamePlaceholder"
          )

      .form__field
        label.input-text
          input.input-text__input(
            type="tel"
            v-model="fieldPhone"
            :placeholder="formFieldPhonePlaceholder"
            v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
          )

      .form__field
        .input-textarea
          textarea.input-textarea__input(
            v-model="fieldText"
            :placeholder="formFieldTextPlaceholder"
          )

    .form__consent
      label.consent.consent---black
        input.consent__input(type="checkbox" v-model="consent" value="consent")
        span.consent__label(v-html="formConsent")

    .form__button
      .button.button---border-orange.button---width-480(@click="getSend()")
        .button__text(v-html="formButtonName" )

  .form__success
    .success.success---form
      .success__text(v-html="formSuccessText")
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  name: 'AppPopupForm',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",

    "formTitle",
    "formButtonName",

    "formFieldName",
    "formFieldNamePlaceholder",
    "formFieldPhone",
    "formFieldPhonePlaceholder",
    "formFieldText",
    "formFieldTextPlaceholder",

    "formConsent",

    "formSuccessText",

    "formRedirect",

    "formPageId",
    "formFieldPageId",
    "formFieldPagePagetitle",
    "formPagePagetitle"
  ],

  data () {
    return {
      fieldName: '',
      fieldPhone: '',
      fieldText: '',
      files: '',
      consent: [],
      sendForm: false
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (!this.v$.$invalid) {
        this.sendForm = !this.sendForm

        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: this.formFieldName,
              value: this.fieldName
            },
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            },
            {
              title: this.formFieldText,
              value: this.fieldText
            },
            {
              title: this.formFieldPageId,
              value: this.formPageId
            },
            {
              title: this.formFieldPagePagetitle,
              value: this.formPagePagetitle
            }
          ],
          files: this.files
        })

        setTimeout(() => {
          // this.sendForm = !this.sendForm
          window.location.href = this.formRedirect
        }, 1500)

        this.fieldName = this.fieldPhone = this.fieldText = ''
      }
    }
  },

  validations () {
    return {
      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },

      consent: {
        required
      }
    }
  }
}
</script>
