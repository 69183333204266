/*global $*/
/*eslint no-undef: "error"*/
export default class Faq {
    classButton = 'faq--button'
    classOpen = 'faq---open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
           $(this).toggleClass(self.classOpen)
        })
    }
}
